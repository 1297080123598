import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/navigation/Layout"
import PageBanner from "../components/common/PageBanner"
import GridViewProperty from "../components/properties/GridViewProperty"

const Communities = () => {
  const data = useStaticQuery(graphql`
    query PropertiesQuery {
      allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }) {
        edges {
          node {
            frontmatter {
              slug
              title
              city
              state
              type
              units
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <PageBanner
        title=""
        backgroundImg="/img/backgrounds/headers/office4.jpg"
      />
      <div className="bg-gray-50 pt-12 sm:pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Bridging the gap
            </h2>
            {/* <p className="mt-3 text-xl text-gray-500 sm:mt-4">
              We look at our business holistically, and understand that we are
              not just in the real estate business,  we are in the people
              business.  The best staff are needed to take care of our
              communities, so we are continually striving to make enhancements
              at the sites, so as to  positively improve our residents lives,
              which will allow us to attract the best staff and residents to our
              communities.
            </p> */}
          </div>
        </div>
        <div className="mt-10 pb-12 bg-white sm:pb-16">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-50" />
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-4xl mx-auto">
                <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-4">
                  <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                      States
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold text-branding">
                      10
                    </dd>
                  </div>
                  <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                      Communities
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold text-branding">
                      27
                    </dd>
                  </div>
                  <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                      Homes
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold text-branding">
                      3,859
                    </dd>
                  </div>
                  <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                      Families
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold text-branding">
                      10,000+
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="listview">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 justify-content-between d-flex align-items-center border-bottom padding-20px-bottom margin-40px-bottom">
              <div>
                <h3 className="no-margin-bottom">Communities</h3>
              </div>
              {/* <div>
                <a href="list-view.html">
                  <i className="fa fa-th-list font-size20 text-extra-dark-gray margin-10px-right"></i>
                </a>
                <a href="grid-view.html">
                  <i className="fa fa-th-large font-size20 text-extra-dark-gray"></i>
                </a>
              </div> */}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 sm-margin-40px-bottom">
              <div className="row margin-50px-bottom">
                {data.allMarkdownRemark.edges.map(edge => {
                  const property = edge.node.frontmatter
                  return (
                    <GridViewProperty key={property.slug} property={property} />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Communities
